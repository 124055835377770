.btn-hover {
    cursor: pointer;
}

.upload-image-container {
    width: 100%;
    min-width: 200px;
    height: 200px;
    .avatar-md {
        width: 100%;
        height: 100% !important;
        object-fit: contain;
    }
}

.select-menu-outer {
    z-index: 999 !important;
}

.menuContainerStyle {
    z-index: 999;
}

.error-message {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: var(--bs-form-invalid-color);
}